<template>
    <div>
        <b-card v-for="(item, index) in $comments.comments" :key="item.id" class="card p-0">
            <b-media>
                <b-row class="item_comments">
                    <b-col cols="12" class="user_head ">
                        <div class="head_userpic">
                            <div class="comments_icon userpic" :style="'background-image: url('+$comments.users[item.user_id].photo_250+')'"></div>
                        </div>
                        <p class="comments_name_header">
                            <span class="comments_name">{{$comments.users[item.user_id].name}} {{$comments.users[item.user_id].f_name}}</span>
                        </p>
                        <p class="comments_name_info">
                            <span class="comments_comment_date" v-html="$moment(item.created_at).local().format('DD.MM.YYYY HH:mm')"></span>
                        </p>
                        <b-button class="del_comment_btn" v-if="$user && $user.id && item.user_id == $user.id" @click="del(item.id)">
                            <font-awesome-icon :icon="['fa', 'times']" class="news_date_icon" />
                        </b-button>
                    </b-col>
                    <b-col cols="12">
                        <div class="comments_comment mb-3" v-html="$cutText($htmContext(item.comment), [text_full === index ? 5000 : 100])">
                        </div>
                        <div v-if="$htmContext(item.comment)" class="comment_box">
                            <div v-if="text_full !== index && $htmContext(item.comment).length > 100 " class="open_full" @click="open_comment(index)">Развернуть</div>
                            <div class="open_full" v-else-if="text_full === index && $htmContext(item.comment).length > 100 " @click="text_full = null">Свернуть</div>
                        </div>
                        <imageLoader v-if="item.image" class="d-flex float-left" :src="item.image"></imageLoader>
                    </b-col>
                </b-row>
            </b-media>
        </b-card>
    </div>
</template>
<script>
import imageLoader from "@/components/ImageLoader/index";
export default {
    props: {
        url: String,
    },
    components: {
        imageLoader
    },
    name: "CommentCard",
    data() {
        return {
            text_full: null,
            limit: 100
        }
    },
    methods: {
        del(id) {
            let form = new FormData();
            form.set('comment_id', id);
            form.set('hide_comment', '1');
            this.$http.post(this.url, form).then((response) => {
                this.$comments.users = response.data.response.users;
                this.$comments.comments = response.data.response.comments;
            })
        },
        open_comment(index) {
            this.text_full = index;
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 767px) {
    .comments_icon {
        width: 40px;
        height: 40px;
    }

    .comments_name_header {
        font-size: 14px;

    }
}

@media only screen and (min-width: 576px) {
    .item_comments {
        padding: 0 !important;
    }
}

@media only screen and (min-width: 767px) {
    .comments_icon {
        width: 40px;
        height: 40px;
    }

    .comments_name_header {
        font-size: 18px;

    }
}

.card {
    border-radius: 12px;
    margin-top: 10px;
    overflow: hidden;
}

.card-body {
    padding: 0px 8px !important;
}

.card-body p {
    margin-bottom: 0px;
}

.card-body {
    padding: 8px;
}

.user_head {
    text-align: left;
    position: relative;
    z-index: 1;
    min-height: 77px;
    padding: 16px 18px 0 70px;

}

.head_userpic {
    position: absolute;
    top: 16px;
    left: 16px;
    text-decoration: none;
    font: 0/0 a;
}

.comments_icon {
    border-radius: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #fff;
    background-position: 50%;
    background-size: cover;
    font-size: 0;

}

.comments_icon:before {
    box-shadow: none;

}

.userpic:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, .5);
    border-radius: 100%;
}

.comments_name_header {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin-right: 70px;
    line-height: 20px;
    margin-left: 7px;
}

.comments_name {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    font-weight: 500;
    font-size: 16px;
}

.comments_name_info {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
    color: #939cb0;
    margin-right: 70px;
    font-size: 14px;
    line-height: 20px;
}

.comments_comment_date {

    font-size: 11px;
    color: #5d5d5f;
}

.comments_comments {
    float: right;
    color: #2c3e50;
    text-decoration: none;
    align-items: center;
}

.comments_comments:hover {
    color: #4a76a8;
}

.del_comment_btn {
    position: absolute;
    right: 16px;
    top: 16px;
    text-decoration: none;
    color: #2c3e50;
    background: transparent;
    border: none;
}

.del_comment_btn:hover {
    color: red;
    background: transparent;
    border: none;
}

.del_comment_btn:active {
    color: red;
    background: transparent;
    border-color: red;
}

.del_comment_btn:focus {
    color: red;
    background: transparent;

}

.del_comment_btn:not(:disabled):not(.disabled):active {
    color: red;
    background: transparent;
    border-color: red;
}

.comments_img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;

}

.comments_comment {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    word-break: break-all
}

.open_full {
    cursor: pointer;

}

.open_full:hover {
    color: #4a76a8;

}

.comment_box {
    text-align: right;
    padding: 0 5px 5px 5px;
    font-size: 12px;
    color: #4a76a8;
}
</style>