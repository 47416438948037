<template>
    <div>
        <div v-if="preloader">
            <div v-if="item" class="org_item">
                <div class="default_header">
                    <h1 v-if="item.name" v-html="item.name"></h1>
                </div>
                <AdsPlaginNew v-if="$myCity && !$myCity.celat_active" :direction="'horizontal'" />
                <div class="org_image_box" v-if="item.images && item.images.length">
                    <imageLoader :items="item.images" loop class="tinybox_main" />
                </div>
                <div class="org_description" v-if="item.description">
                    <label>О компании</label>
                    <div v-html="$htmContext(item.description)"></div>
                </div>
                <div class="org_addresses" v-if="item.addresses && item.addresses.length">
                    <label>Адрес</label>
                    <div v-for="(item, index) in item.addresses" :key="index">
                        <span class="org_address_title">{{ item.address }}</span>
                        <span class="org_address_value">{{ item.working_days }}</span>
                    </div>
                </div>
                <div class="org_phones" v-if="item.telephones && item.telephones.length">
                    <label>Контакты</label>
                    <div v-for="(item, index) in item.telephones" :key="index">
                        <span class="org_address_title"
                            ><a :href="`tel:${item.number}`">{{ item.number }}</a></span
                        >
                        <span class="org_address_value">{{ item.name }}</span>
                    </div>
                </div>
                <div class="org_sites" v-if="item.sites && item.sites.length">
                    <label>Ссылки</label>
                    <div v-for="(item, index) in item.sites" :key="index">
                        <span class="org_address_title"
                            ><a :href="item.url" target="_blank">{{ item.url }}</a></span
                        >
                    </div>
                </div>
                <div class="shares_box" v-if="item.shares && item.shares.length">
                    <div v-for="(item, index) in item.shares" :key="index" class="shares_item">
                        <div class="share_item_image_box">
                            <img :src="item.image" alt="image" />
                        </div>
                        <span>{{ item.name }}</span>
                        <router-link :to="{ name: 'Shares', params: { id: item.id } }">Подробнее</router-link>
                    </div>
                </div>
                <VkFrame v-if="item && item.vk_video && item.vk_video.url" :item="item.vk_video"></VkFrame>
                <YouTube v-if="item && item.youtube" :item="item.youtube"></YouTube>
                <Comments :url="url" :comments="comments" :title="item.name" :image="item.image ? item.image : $logo_og" :users="users"></Comments>
            </div>
        </div>
        <PreLoadBox v-else />
    </div>
</template>
<script>
import YouTube from "@/components/YouTube/index";
import VkFrame from "@/components/Plagins/VkFrame/index";
import imageLoader from "@/components/ImageLoader/imageLoader";
import Comments from "@/components/App/Organisation/Components/Comments/index";
export default {
    components: {
        YouTube,
        VkFrame,
        imageLoader,
        Comments,
    },
    metaInfo() {
        return {
            title: this.item && this.item.name ? `${this.item.name} - ` : null,
            meta: [{
                    name: 'keywords',
                    itemprop: 'keywords',
                    content: this.item && this.item.keywords && this.item.keywords.length ? `${this.$toString(this.item.keywords)}` : ''
                },
                {
                    name: 'description',
                    itemprop: 'description',
                    content: this.item && this.item.name ? `${this.item.name} - Телефон, адрес, часы работы, схема проезда, отзывы, фото и видео.` : 'Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.'
                },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                {
                    property: 'og:title',
                    content: this.item && this.item.name ? `${this.item.name} - ${this.$myCity.name}` : `Справочник организаций ${this.$myCity.name}`
                },
                {
                    property: 'og:description',
                    content: this.item && this.item.name ? `${this.item.name} - Телефон, адрес, часы работы, схема проезда, отзывы, фото и видео.` : 'Подробная карта городов России: поиск по адресу, телефоны, отзывы, фото и часы работы фирм.'
                },
                {
                    property: 'og:image',
                    content: this.item && this.item.images && this.item.images[0] && this.item.images[0] ? this.item.images[0] : 'https://okaygorod.com/images/logoHD.png'
                },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preloader: false,
            item: {},
            comments: null,
            users: null,
        };
    },
    methods: {
        api() {
            this.preloader = false;
            this.$scrollToTop();
            this.$http
                .get(this.url)
                .then((response) => {
                    this.item = response.data.response.item;
                    this.comments = response.data.response.comments;
                    this.users = Object.assign({}, response.data.response.users);
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push({ name: "NotFound" });
                    } else if (error.response.status === 462) {
                        this.$router.push({ name: "OrganisationCat" });
                    }
                })
                .finally(() => {
                    this.preloader = true;
                });
        },
    },
    computed: {
        url: {
            get: function () {
                return this.$config.api_url + this.$route.params.city + "/Organizations.get/" + this.$route.params.id;
            },
        },
    },
    mounted() {
        this.api();
    },
};
</script>
<style scoped>
.org_image_box {
    /* padding: 15px; */
    margin-bottom: 20px;
}

.org_description {
    padding: 15px;
    background: #fff;
    border-radius: 12px;
    text-align: left;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
}

.org_sites,
.org_phones,
.org_addresses {
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 20px;
}

.org_sites label,
.org_phones label,
.org_description label,
.org_addresses label {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    display: inline-flex;
    justify-self: flex-start;
    text-align: left;
    background: #eee;
    padding: 5px 25px;
    border-radius: 4px;
    color: #21201f;
}

.org_sites > div,
.org_phones > div,
.org_addresses > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.org_address_title {
    font-size: 16px;
    color: #21201f;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}
.org_address_value {
    font-size: 14px;
    color: #919191;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    text-align: left;
}

.shares_box {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 20px;
}

.shares_item {
    width: calc(100% / 4 - (15px * 3 / 4));
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
}

@media (max-width: 768px) {
    .org_item {
        padding: 15px;
    }
    .shares_item {
        width: calc(100% / 2 - (15px * 1 / 2));
    }
}

.shares_item > span {
    font-size: 12px;
    text-align: left;
    display: block;
    padding: 0 10px;
    color: #21201f;
    font-family: "Roboto", sans-serif;
}

.shares_item a {
    background: #21201f;
    color: #fff;
    padding: 5px 15px;
}
</style>
