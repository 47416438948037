<template>
    <div>
        <div v-if="preLoad">
            <b-col class="con mb-2">
                <div v-if="form.file" class="img_preloader">
                    <b-img :src="img" alt="Предзагрузка фото"></b-img>
                    <b-button class="btn-del btn-danger" @click="form.file = null">X</b-button>
                </div>
                <b-row>
                    <b-col class="input_box p-0">
                        <div class="upload-btn-wrapper">
                            <b-form-file v-model="form.file" placeholder="" drop-placeholder="" accept="image/jpeg, image/png" plain class="file-upload"></b-form-file>
                            <button class="btn_file"><img class="file_img" src="/images/icon/paperclip.svg" alt="Фото" /></button>
                        </div>
                        <textarea v-model="form.text" placeholder="" min-rows="3" max-rows="6" class="textarea_ads col form-control"></textarea>
                        <div class="btn-send_box">
                            <img class="btn-send" src="/images/icon/send.svg" alt="Отправить" @click="send" :disabled="button.disabled" />
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </div>
        <div v-else>
            <PreLoadBox></PreLoadBox>
        </div>
    </div>
</template>
<script>
import PreLoadBox from "@/components/Preloader/coin";
export default {
    name: "CommentsForm",
    props: {
        url: String,
        commentsId: Number,
    },
    components: {
        PreLoadBox,
    },
    data() {
        return {
            preLoad: 'finish',
            form: {
                file: null,
                text: '',
            },
            img: null,
            button: {
                disabled: false,
                text: (!this.commentsId) ? 'Send' : 'Update',
            },
        }
    },
    methods: {
        send() {
            this.preLoad = null;
            let form = new FormData();
            form.set('comment', this.form.text);
            if (this.form.file) form.set('pic', this.form.file);
            if (this.commentsId) form.set('comment_id', this.commentsId);
            this.button.disabled = true;
            this.button.text = (!this.commentsId) ? 'Sending' : 'Updated';
            this.$http.post(this.url, form).then((response) => {
                this.formReset();
                this.$comments.comments = response.data.response.comments;
                this.$comments.users = response.data.response.users;
            }).finally(() => {
                this.button.disabled = false;
                this.button.text = (!this.commentsId) ? 'Send' : 'Update';
                this.preLoad = 'finish';
            });
        },
        formReset() {
            this.form.file = null;
            this.form.text = '';
        },
    },
    watch: {
        'form.file': function() {
            if (this.form.file) {
                let image = new Image();
                image.src = URL.createObjectURL(this.form.file);
                image.onload = () => {
                    if (image.width > 149 && image.height > 149) {
                        this.img = URL.createObjectURL(this.form.file);
                    } else {
                        this.form.file = null;
                        alert('Минимальный размер картинки 150х150 пикселей');
                    }

                }
            }
        },
    },
}
</script>
<style scoped>
.commentFile {
    position: absolute;
    top: 7px;
    left: 8px;
    color: #4a76a8;
    max-height: 10px;

}

.img_preloader {
    position: relative;
    display: flex;
    margin-bottom: 5px;
}

.img_preloader button {
    height: 100px;
}

.img_preloader img {
    max-height: 100px;
    max-width: 250px;
}

.btn-send_box {
    width: 45px;
    height: 45px;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.btn-send {
    height: 30px;
    width: 30px;
    background: #fff;
}

.upload-btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;

}

.btn_file {
    width: 45px;
    height: 45px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background: transparent;
}

.file_img {
    height: 30px;
    width: 30px;
    background: #fff;
}

.btn-del {
    height: 100%;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    opacity: 0;
}

.textarea_ads {
    min-height: 60px;
    padding-left: 50px;
    padding-right: 50px;
}
</style>