<template>
    <div>
        <div class="org_get_comments_title">Отзывы</div>
        <div class="org_get_add_comment" v-b-modal.modal-center>Добавить отзыв</div>
        <b-modal ref="modal-center" id="modal-center" centered :hide-header="true" :hide-footer="true">
            <b-row no-gutters class="p-3">
                <div v-if="!$mobileWidth()" class="modal_title_image_box">
                    <img :src="image" alt="" class="comment_title_img">
                </div>
                <b-col cols="10" class="modal_title">{{title}}</b-col>
            </b-row>
            <div class="comments_border mb-2"></div>
            <div v-if="$user">
                <div v-if="preLoad">
                    <b-row no-gutters class="p-3">
                        <b-col class="input_box p-0">
                            <div class="upload-btn-wrapper">
                                <b-form-file v-model="form.file" placeholder="" drop-placeholder="" accept="image/jpeg, image/png" plain class="file-upload"></b-form-file>
                                <button class="btn_file"><img class="file_img" src="/images/icon/paperclip.svg" alt="Фото" /></button>
                            </div>
                            <textarea v-model="form.text" placeholder="" min-rows="5" max-rows="40" class="comments_textarea_ads form-control"></textarea>
                            <div class="btn-send_box">
                                <img class="btn-send" src="/images/icon/send.svg" alt="Отправить" @click="send" :disabled="button.disabled" />
                            </div>
                            <div v-if="form.file" class="img_preloader">
                                <b-img :src="img" alt="Предзагрузка фото"></b-img>
                                <b-button class="btn-del btn-danger" @click="form.file = null">X</b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <div class="button_box">
                        <div class="but but-next" @click="hideModal">Закрыть</div>
                    </div>
                </div>
                <div v-else>
                    <PreLoadBox />
                </div>
            </div>
            <div v-else>
                <div class="comments_user_login">
                    <div class="login" show>Авторизуйтесь, чтобы оставить комментарий.<br>
                        У вас еще нет аккаунта ?
                        <router-link :to="{name: 'UserRegistration',params: { city:$route.params.city }}">
                            Зарегистрироваться
                        </router-link>
                    </div>
                </div>
                
                <UserLogin />
                <div class="button_box">
                        <div class="but but-next" @click="hideModal">Закрыть</div>
                    </div>
            </div>
        </b-modal>
        <!--Comments-->
        <CommentCard :url="url"></CommentCard>
        <!--END Comments-->
    </div>
</template>
<script>
/* eslint-disable */
import './model'

import UserLogin from "@/components/App/User/UserLogin";
import CommentCard from "@/components/App/Organisation/Components/Comments/Card";
import CommentsForm from "@/components/App/Organisation/Components/Comments/Form";

export default {
    name: "Comments",
    components: { CommentsForm, CommentCard, UserLogin },
    props: {
        url: String,
        comments: Array,
        users: Object,
        title: String,
        image: String
    },
    data() {
        return {
            preLoad: 'finish',
            form: {
                file: null,
                text: '',
            },
            img: null,
            button: {
                disabled: false,
                text: 'Send',
            },

        }
    },
    methods: {
        send() {
            if (!this.form.file && !this.form.text) {
                alert('Добавьте фото или комментарий!')
            } else {
                this.preLoad = null;
                let form = new FormData();
                form.set('comment', this.form.text);
                if (this.form.file) form.set('pic', this.form.file);
                this.button.disabled = true;
                this.button.text = 'Sending';
                this.$http.post(this.url, form).then((response) => {
                    this.formReset();
                    this.$comments.comments = response.data.response.comments;
                    this.$comments.users = response.data.response.users;
                }).finally(() => {
                    this.button.disabled = false;
                    this.button.text = 'Send';
                    this.preLoad = 'finish';
                    this.$refs['modal-center'].hide()
                });
            }

        },
        hideModal() {
            this.$refs['modal-center'].hide()
        },
        formReset() {
            this.form.file = null;
            this.form.text = '';
        },
    },

    mounted() {
        this.$comments.comments = this.comments;
        this.$comments.users = this.users;
    },
    watch: {
        'comments': function() {
            this.$comments.comments = this.comments;
        },
        'users': function() {
            this.$comments.users = this.users;
        },
        'form.file': function() {
            if (this.form.file) {
                let image = new Image();
                image.src = URL.createObjectURL(this.form.file);
                image.onload = () => {
                    if (image.width > 149 && image.height > 149) {
                        this.img = URL.createObjectURL(this.form.file);
                    } else {
                        this.form.file = null;
                        alert('Минимальный размер картинки 150х150 пикселей');
                    }

                }
            }
        },
    },
    destroyed() {
        this.$comments.comments = [];
        this.$comments.users = {}
    }
}
</script>
<style scoped>
@media only screen and (max-width: 450px) {
    .login {
        font-size: 12px;
    }
}

@media only screen and (min-width: 451px) and (max-width: 767px) {
    .login {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .login {
        font-size: 16px;
    }
}
.comment_title {
    padding: 5px;
    margin: 0 0 10px 0px;
    font-weight: bold;
}

.card {
    border-radius: 12px;
    margin-bottom: 24px;
}

.comments_user_login {
    padding: 0 1rem 0 1rem;
}

.login {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);    
    margin-bottom: 10px;
    text-align: center;
}

.login a {
    text-decoration: none;
}
.org_get_comments_title {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.org_get_add_comment {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #4a76a8;
    color: #fff;
    cursor: pointer;
}

.modal_title_image_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    padding: 5px;
    margin-right: 10px;
}

.modal_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}

.comment_title_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.comments_border {
    border-bottom: 1px solid #dee2e6;
}

.upload-btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;

}

.btn_file {
    width: 45px;
    height: 45px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background: transparent;
}

.file_img {
    height: 30px;
    width: 30px;
    background: #fff;
}

.btn-del {
    height: 100%;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    opacity: 0;
}

.comments_textarea_ads {
    min-height: 150px;
    padding-left: 50px;
    padding-right: 50px;
}

.commentFile {
    position: absolute;
    top: 7px;
    left: 8px;
    color: #4a76a8;
    max-height: 10px;

}

.img_preloader {
    position: relative;
    display: flex;
    margin-bottom: 5px;
}

.img_preloader button {
    height: 100px;
}

.img_preloader img {
    max-height: 100px;
    max-width: 250px;
}

.btn-send_box {
    width: 45px;
    height: 45px;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.btn-send {
    height: 30px;
    width: 30px;
    background: #fff;
}

.button_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
}

.but {
    padding: 5px 15px;
}
</style>